export async function getPrograms({ commit }, id) {
  const response = await this.$api.get(
    `users/${id}/programs?filter[show_deactivated]=true`
  )
  commit('setPrograms', response.data.data)
}
export async function regenerateValidUntil({ commit }, id) {
  return this.$api.post(`users/${id}/programs/regenerate`)
}

export async function getPauses({ commit }, id) {
  const response = await this.$api.get(`users/${id}/pauses`)
  commit('setPauses', response.data.data)
}

export async function getProgramRevenue({ commit }, id) {
  const response = await this.$api.get(`users/${id}/program-revenues`)
  commit('setProgramRevenues', response.data.data)
  commit('setProgramRevenueTotal', response.data.total)
}

export async function getProgramDetails({ commit }, params) {
  const { id, programId } = params
  const response = await this.$api.get(`users/${id}/programs/${programId}`)
  commit('setSelectedProgram', response.data.data)
}

export async function updateProgram({ commit }, params) {
  const { id, programId, form } = params

  return this.$api.patch(`users/${id}/programs/${programId}`, form)
}

export function deactivateUserProgram({ commit }, params) {
  const { userId, programId } = params

  return this.$api.post(`users/${userId}/programs/${programId}/deactivate`)
}

export function changeUserProgramIntake({ commit }, params) {
  const { userId, programId, intakeDate, validUntilDate } = params

  return this.$api.post(`users/${userId}/programs/${programId}/intake`, {
    intake_date: intakeDate,
    valid_until: validUntilDate,
  })
}

export function changeUserProgram({ commit }, params) {
  const { userId, programId, newProgram, variantId } = params

  return this.$api.post(`users/${userId}/programs/${programId}/change`, {
    program_id: newProgram,
    variant_id: variantId,
  })
}

export async function getMemberships({ commit }, id) {
  const response = await this.$api.get(
    `users/${id}/subscriptions?filter[show_deactivated]=true`
  )
  commit('setMemberships', response.data.data)
}

export async function getMembershipDetails({ commit }, params) {
  const { id, userSubscriptionId } = params
  const response = await this.$api.get(
    `users/${id}/subscriptions/${userSubscriptionId}`
  )
  commit('setSelectedMembership', response.data.data)
}

export function deactivateUserMembership({ commit }, params) {
  const { userId, userSubscriptionId } = params
  return this.$api.post(
    `users/${userId}/subscriptions/${userSubscriptionId}/deactivate`,
    {
      deactivated_at: params.deactivated_at,
    }
  )
}

export function reactivateUserMembership({ commit }, params) {
  const { userId, userSubscriptionId } = params
  return this.$api.post(
    `users/${userId}/subscriptions/${userSubscriptionId}/reactivate`
  )
}

export function pauseUserMembership({ commit }, params) {
  const { userId } = params

  return this.$api.post(`users/${userId}/pauses`, {
    user_subscription_id: params.userSubscriptionId,
    active: true,
    start_date: params.start_date,
    end_date: params.end_date,
    justification: params.justification,
  })
}

export function unpauseUserMembership({ commit }, params) {
  const { userId, pauseDetails } = params
  return this.$api.put(`users/${userId}/pauses/${pauseDetails.id}`, {
    user_subscription_id: pauseDetails.user_subscription_id,
    deactivated_at: pauseDetails.deactivated_at,
    active: false,
    start_date: pauseDetails.start_date,
    end_date: pauseDetails.end_date,
    justification: pauseDetails.justification,
  })
}

export function changeUserMembershipValidity({ commit }, params) {
  const { userId, userSubscriptionId, validUntilDate } = params

  return this.$api.post(
    `users/${userId}/subscriptions/${userSubscriptionId}/change-validity`,
    {
      valid_until: validUntilDate,
    }
  )
}
